import {
  SAVE_USER_LOGGED,
  SAVE_TOKEN_LOGGED
} from '../types'


export default (state, action) => {
  switch (action.type) {
    case SAVE_USER_LOGGED:
      return {
        ...state,
        user: action.payload
      }
    case SAVE_TOKEN_LOGGED:
      return {
        ...state,
        token: action.payload
      }
    default:
      return state
  }
}