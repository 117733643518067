import React, { Component } from "react"
import {
  Segment,
  Form,
  Header,
  Icon,
  Divider,
  Button,
} from "semantic-ui-react";
import feathers from "../../../feathers-client";
import swal from "sweetalert";

const INITIAL_DATA = {
  full_name: "",
  clase_id: 0,
  rol: "ALUMNO"
}

const ROLES_DATA = [
  {
    key: 0,
    text: "Alumno",
    value: "ALUMNO"
  },
  {
    key: 1,
    text: "Líder Misionero",
    value: "LIDER"
  },
  {
    key: 2,
    text: "Maestro",
    value: "MAESTRO"
  }
]

class CreateAlumno extends Component {
  state = {
    loading: false,
    clasesLoading: true,
    student: INITIAL_DATA,
    clases: [],
    roles: ROLES_DATA
  }

  componentDidMount() {
    this.getClases();
  }

  getClases = () => {
    const clases = feathers.service("clases");
    clases.find({
      query: {
        $limit: 1000
      }
    })
      .then(res => {
        const clases = res.data.map((c, i) => ({
          key: i,
          text: c.name,
          value: c.id
        }))
        this.setState({clases, clasesLoading: false})
      })
  }

  handleChange = (e) => {
    this.setState({
      student: {
        ...this.state.student,
        [e.target.name]: e.target.value
      },
    });
  };

  handleChangeSelect = (event, result) => {
    const { name, value } = result || event.target;
    this.setState({
      student: {
        ...this.state.student,
        [name]: value
      }
    });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    await feathers
      .service("students")
      .create(this.state.student)
      .then(res => {
        console.log(res);
        swal({
          icon: "success",
          title: "Alumno Agregado",
          text: "El alumno se ha agregado correctamente",
          timer: 1500
        });
        this.setState({
          student: INITIAL_DATA,
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        swal(
          "Error",
          "Ha ocurrido un error. Por favor intente mas tarde",
          "error"
        );
        this.setState({ loading: false });
      });
  };

  render() {
    return (
     <Segment loading={this.state.loading}>
       <Header as="h1">
          <Icon name="plus" size="large" />
          <Header.Content>Agregar Alumno</Header.Content>
        </Header>
        <Divider />
        <Form style={{ textAlign: "left"}}>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="full_name"
              label="Nombre del Alumno"
              value={this.state.student.full_name}
              onChange={this.handleChange}
            />
            <Form.Select
              required
              fluid
              label="Clase:"
              value={this.state.student.clase_id}
              name="clase_id"
              options={this.state.clases}
              onChange={this.handleChangeSelect}
            />
            <Form.Select
              required
              fluid
              label="Rol:"
              value={this.state.student.rol}
              name="rol"
              options={this.state.roles}
              onChange={this.handleChangeSelect}
            />
          </Form.Group>
          <Button
            disabled={
              this.state.student.full_name === "" ||
              this.state.student.clase_id === 0
            }
            primary content="Registrar" 
            onClick={this.handleSubmit} 
          />
        </Form>
     </Segment>
    )
  }
}

export default CreateAlumno;
