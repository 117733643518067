const sabaths = [
  {
    key: "Lección 1",
    text: "Lección 1",
    value: "2020-10-03",
    quarter: "2020Q4"
  },
  {
    key: "Lección 2",
    text: "Lección 2",
    value: "2020-10-10",
    quarter: "2020Q4"
  },
  {
    key: "Lección 3",
    text: "Lección 3",
    value: "2020-10-17",
    quarter: "2020Q4"
  },
  {
    key: "Lección 4",
    text: "Lección 4",
    value: "2020-10-24",
    quarter: "2020Q4"
  },
  {
    key: "Lección 5",
    text: "Lección 5",
    value: "2020-10-31",
    quarter: "2020Q4"
  },
  {
    key: "Lección 6",
    text: "Lección 6",
    value: "2020-11-07",
    quarter: "2020Q4"
  },
  {
    key: "Lección 7",
    text: "Lección 7",
    value: "2020-11-14",
    quarter: "2020Q4"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2020-11-21",
    quarter: "2020Q4"
  },
  {
    key: "Lección 9",
    text: "Lección 9",
    value: "2020-11-28",
    quarter: "2020Q4"
  },
  {
    key: "Lección 10",
    text: "Lección 10",
    value: "2020-12-05",
    quarter: "2020Q4"
  },
  {
    key: "Lección 11",
    text: "Lección 11",
    value: "2020-12-12",
    quarter: "2020Q4"
  },
  {
    key: "Lección 12",
    text: "Lección 12",
    value: "2020-12-19",
    quarter: "2020Q4"
  },
  {
    key: "Lección 13",
    text: "Lección 13",
    value: "2020-12-26",
    quarter: "2020Q4"
  },
  {
    key: "Lección 1",
    text: "Lección 1",
    value: "2021-01-02",
    quarter: "2021Q1"
  },
  {
    key: "Lección 2",
    text: "Lección 2",
    value: "2021-01-09",
    quarter: "2021Q1"
  },
  {
    key: "Lección 3",
    text: "Lección 3",
    value: "2021-01-16",
    quarter: "2021Q1"
  },
  {
    key: "Lección 4",
    text: "Lección 4",
    value: "2021-01-23",
    quarter: "2021Q1"
  },
  {
    key: "Lección 5",
    text: "Lección 5",
    value: "2021-01-30",
    quarter: "2021Q1"
  },
  {
    key: "Lección 6",
    text: "Lección 6",
    value: "2021-02-06",
    quarter: "2021Q1"
  },
  {
    key: "Lección 7",
    text: "Lección 7",
    value: "2021-02-13",
    quarter: "2021Q1"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2021-02-20",
    quarter: "2021Q1"
  },
  {
    key: "Lección 9",
    text: "Lección 9",
    value: "2021-02-27",
    quarter: "2021Q1"
  },
  {
    key: "Lección 10",
    text: "Lección 10",
    value: "2021-03-06",
    quarter: "2021Q1"
  },
  {
    key: "Lección 11",
    text: "Lección 11",
    value: "2021-03-13",
    quarter: "2021Q1"
  },
  {
    key: "Lección 12",
    text: "Lección 12",
    value: "2021-03-20",
    quarter: "2021Q1"
  },
  {
    key: "Lección 13",
    text: "Lección 13",
    value: "2021-03-27",
    quarter: "2021Q1"
  }
]

export default sabaths;