import React, { useReducer } from 'react';
import ReporteContext from "./reporteContext"
import ReporteReducer from "./reporteReducer"
import feathers from "../../feathers-client"
import {
  GET_REPORTE_SEMAFORO,
  GET_REPORTE_SEMAFORO_GP
} from "../types"

const ReporteState = props => {
  const initialState = {
    reporte: [],
    reporteGP: [],
  }

  const [state, dispatch] = useReducer(ReporteReducer, initialState)

  // GET reporte semaforo para cada clase
  const getReporteSemaforoClase = (claseId) => {
    feathers.service("reporte-clase").get(claseId)
      .then(res => {
        const arr_reverse = []
        for (let i = 0; i < res[0].length; i++) {
          arr_reverse.push( [i + 1, res[0][i], res[1][i], res[2][i]] )
        }
        dispatch({
          type: GET_REPORTE_SEMAFORO,
          payload: arr_reverse
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

  // GET reporte semaforo para cada clase
  const getReporteSemaforoClaseGP = (claseId) => {
    feathers.service("reporte-clase-grupo").get(claseId)
      .then(res => {
        const arr_reverse = []
        for (let i = 0; i < res[0].length; i++) {
          arr_reverse.push( [i + 1, res[0][i]] )
        }
        console.log(arr_reverse);
        dispatch({
          type: GET_REPORTE_SEMAFORO_GP,
          payload: arr_reverse
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

  return <ReporteContext.Provider
    value={{
      reporte: state.reporte,
      reporteGP: state.reporteGP,
      getReporteSemaforoClase,
      getReporteSemaforoClaseGP
    }}
  >
    {props.children}
  </ReporteContext.Provider>
}

export default ReporteState