import React from 'react';
import { Segment, Grid, Header, Icon, Table } from "semantic-ui-react";
import sabaths from "../../../utils/sabaths";



export default function ReporteGeneral({GeneralReport, Quarter, Loading}){



    return(
        <Segment loading={Loading}>
            <Grid>
                <Grid.Column width={12}>
                <Header as="h1">
                    <Icon name="file" color="red"  size="tiny"/>
                    <Header.Content>Reporte General Grupo Pequeño</Header.Content>
                </Header>
                </Grid.Column>
            </Grid>

            <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell >
                  Clase / Semana
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                {
                  sabaths.filter( v => v.quarter == Quarter).map(m => 
                    <Table.HeaderCell >
                      {m.text.substring(8,10)}
                    </Table.HeaderCell>
                  )
                }
                <Table.HeaderCell >
                  Promedio General
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
                {GeneralReport.map( (r, i) => {
                  return (
                    <Table.Row key={i}>

                      <Table.Cell >{r.clase_name}</Table.Cell>
                      <Table.Cell ><Icon name="clock" /></Table.Cell>

                      {r.sabaths.map((s, j) => {
                        return (
                          <Table.Cell key={j} positive={s>=80} warning={s>=60&&s<80} negative={s<60}>
                            {Math.round(s) + "%"}
                          </Table.Cell>
                        )
                      })}
                        <Table.Cell 
                          positive={Math.round(r.sabaths.reduce( (a, c) => a + c,  0) / 13)>=80} 
                          warning={Math.round(r.sabaths.reduce( (a, c) => a + c,  0) / 13)>=60&&Math.round(r.sabaths.reduce( (a, c) => a + c,  0) / 13)<80} 
                          negative={Math.round(r.sabaths.reduce( (a, c) => a + c,  0) / 13)<60}>
                            {Math.round(r.sabaths.reduce( (a, c) => a + c,  0) / 13) + "%"}
                        </Table.Cell>
                    </Table.Row>
                  )
                })}
               
            </Table.Body>
           
          </Table>
        </Segment>
    )
}



