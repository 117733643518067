import React, { Component } from "react"
import {
  Segment,
  Form,
  Header,
  Icon,
  Divider,
  Button
} from "semantic-ui-react";
import feathers from "../../../feathers-client";
import swal from "sweetalert";

class CreateClass extends Component {
  state = {
    loading: false,
    class: {
      name: ""
    }
  }

  handleChange = (e) => {
    this.setState({
      class: {
        ...this.state.class,
        [e.target.name]: e.target.value
      },
    });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    
    await feathers
      .service("clases")
      .create(this.state.class)
      .then(res => {
        console.log(res);
        swal(
          "Clase Agregada",
          "La clase se ha agregado correctamente",
          "success",
          {timer: 1500}
        );
        this.setState({
          class: {
            name: ""
          },
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        swal(
          "Error",
          "Ha ocurrido un error. Por favor intente mas tarde",
          "error"
        );
        this.setState({ loading: false });
      });
  };

  render() {
    return (
     <Segment loading={this.state.loading}>
       <Header as="h1">
          <Icon name="plus" size="large" />
          <Header.Content>Agregar Clase</Header.Content>
        </Header>
        <Divider />
        <Form style={{ textAlign: "left"}}>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="name"
              label="Nombre de la Clase"
              value={this.state.class.name}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Button disabled={this.state.class.name === ""} primary content="Registrar" onClick={this.handleSubmit} />
        </Form>
     </Segment>
    )
  }
}

export default CreateClass;