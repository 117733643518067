import React, { useState, useEffect } from 'react';
import ReporteGeneral from './ReporteGeneral'
import ReportePorClase from './ReportePorClase'
import { Segment, Grid, Form, Select } from "semantic-ui-react";
import sabaths from "../../../utils/sabaths-gp";
import quarters from "../../../utils/quarters";
import moment from 'moment';
import feathers from "../../../feathers-client";


export default function ReporteGeneralGrupo(){

    const Reporte = feathers.service("reporte-grupo")
    const [Quarter, setQuarter] = useState(moment().year() + "Q" + (Math.ceil(moment().week() / 13) ))
    const [GeneralReport, setGeneralReport] = useState([])
    const [Loading, setLoading] = useState(false)
    const [Sabath, setSabath] = useState(sabaths.filter(v => v.quarter == Quarter)[0].value)
    const [QuarterWeek, setQuarterWeek] = useState( moment(sabaths.filter(v => v.quarter == Quarter)[0].value).week() % 13 )


    useEffect(() => {
      setLoading(true)
      let week = moment().week();
      let quarterWeek = Math.round(week % 13);
      setQuarterWeek(quarterWeek);

      Reporte.find({
        query: {
          quarter: Quarter
        }
      }).then( r => {
        setGeneralReport(r);
        setLoading(false)
      }).catch(e => console.log(e))

    }, [Quarter])

    function handleChangeSabath(_, result){
        setSabath(result.value)
        setQuarterWeek(moment(result.value).week() % 13)
    }
    
    function handleChangeQuarter(_, result){
        setQuarter(result.value)
    }

    
      
    return(
        <React.Fragment>
            <Segment>
            <Grid style={{ padding: "0 20px"}}>
            <Grid.Row columns={2} textAlign="left">

              <Grid.Column>
                <Form.Field>
                  <Select
                    label="Trimestre"
                    value={Quarter}
                    options={quarters}
                    name="quarterSelected"
                    onChange={handleChangeQuarter}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
              <Form.Field>
                <Select
                  label="Clase"
                  value={Sabath}
                  options={sabaths.filter(v => v.quarter === Quarter)}
                  name="dateSelected"
                  onChange={handleChangeSabath}
                />
                <label style={{ paddingLeft: "10px" }}>{Sabath}</label>
                    </Form.Field>
              </Grid.Column>
                 
            </Grid.Row>
            </Grid>
            </Segment>

          
            
            <ReportePorClase Quarter={Quarter} Loading={Loading} GeneralReport={GeneralReport} QuarterWeek={QuarterWeek}/>
            <ReporteGeneral Quarter={Quarter}  Loading={Loading} GeneralReport={GeneralReport}/>

        </React.Fragment>
    )
}