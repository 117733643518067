import React, { useContext, useState, useEffect } from 'react';
import {
  Segment,
  Icon,
  Header,
  Divider,
  Grid,
  Form,
  Select,
  Table,
  Modal
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import CatalogosContext from '../../Context/CatalogosContext/catalogosContext';
import { formatPercentage } from '../../utils/functions';

const ListaIntereses = () => {
  const catalogosContext = useContext(CatalogosContext);
  const { clases, claseSelected, intereses, loading, setLoading, getIntereses, setClaseSelected, getClases } = catalogosContext;

  // const [loading, setLoading] = useState(true);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [dataModal, setDataModal] = useState({lecciones: []});
  const [token, setToken] = useState({})

  useEffect(() => {
    const tokenLS = JSON.parse(localStorage.getItem("token"));
    setToken(tokenLS)
    getClases(tokenLS.rol === "Director", tokenLS.clase_id);
  }, []);

  useEffect(() => {
    if(clases.length !== 0) {
      const claseSelectedTemp = token.rol === "Director" ? claseSelected : token.clase_id
      setClaseSelected(claseSelected)
      getIntereses(claseSelected);
    }
  }, [clases]);

  const handleChangeClass = (event, result) => {
    const { value } = result || event.target;
    setClaseSelected(value)
    getIntereses(value);
  };

  const handleShowLecciones = (lecciones, instructor, interes, curso) => {
    setDataModal({
      lecciones, instructor, interes, curso
    })
    setisModalOpen(true);
  };

  const handleLinkEstudio = (link) => {
    console.log(link);
    navigator.clipboard.writeText(link)
  }

  const handleCloseModal = () => {
    setisModalOpen(false);
  }

  const handleDelete = (Id) => {
    setLoading(true)
    swal({
      title: "¿Seguro desea eliminar el miembro?",
      text:
        "Esta operación es irreversible",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      // if (willDelete) {
      //   feathers
      //     .service("students-grupo")
      //     .remove(Id)
      //     .then(res => {
      //       swal("Miembro eliminado correctamente", { icon: "success", timer: 2000 });
      //       getMiembros(claseSelected);
      //     })
      //     .catch(err => {
      //       console.log(err);
      //       swal("Ocurrió un error. Intente más tarde", { icon: "error" });
      //     });
      // }
    });
    setLoading(false)
  };

  return (
    <Segment loading={loading}>
      <Grid>
        <Grid.Column width={12}>
          <Header as="h1">
            <Icon name="list" />
            <Header.Content>Listado de Miembros de Grupo Pequeño</Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={4}>
          <Link to="alta-interes">
          <Icon name='plus' size="big" color="green" />
          </Link>
        </Grid.Column>
      </Grid>

      <Divider />
      
      <Grid style={{ padding: "0 20px"}}>
        <Grid.Row columns={4} textAlign="left">
          <Grid.Column>
            <Form.Field>
              <Select
                label="Clase"
                value={claseSelected}
                options={clases}
                name="claseSelected"
                onChange={handleChangeClass}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row width={16}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Instructor</Table.HeaderCell>
                <Table.HeaderCell>Interés</Table.HeaderCell>
                <Table.HeaderCell>Dirección</Table.HeaderCell>
                <Table.HeaderCell>Teléfono</Table.HeaderCell>
                <Table.HeaderCell>Edad</Table.HeaderCell>
                <Table.HeaderCell>Curso</Table.HeaderCell>
                <Table.HeaderCell>Avance</Table.HeaderCell>
                <Table.HeaderCell>Link estudio</Table.HeaderCell>
                <Table.HeaderCell>Lecciones</Table.HeaderCell>
                <Table.HeaderCell>Acciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                intereses.map((i, index) => 
                  <Table.Row key={index}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{i.instructor}</Table.Cell>
                    <Table.Cell>{i.interes}</Table.Cell>
                    <Table.Cell>{i.direccion}</Table.Cell>
                    <Table.Cell>{i.telefono}</Table.Cell>
                    <Table.Cell>{i.edad}</Table.Cell>
                    <Table.Cell>{i.curso}</Table.Cell>
                    <Table.Cell positive={i.avance>=0.80} warning={i.avance>=0.60&&i.avance<0.80} negative={i.avance<0.60}>
                      {formatPercentage(i.avance) + "%"}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to="#" onClick={() => handleLinkEstudio(i.link)}>
                        <Icon name="share" color="grey" /> Copiar
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link to="#" onClick={() => handleShowLecciones(i.lecciones, i.instructor, i.interes, i.curso)}>
                        <Icon name="copy" color="grey" /> Ver
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {/* <Link to="#" onClick={() => handleDelete(i.id)}>
                        <Icon name="trash" color="grey" /> Eliminar
                      </Link>
                      &nbsp;&nbsp;&nbsp;&nbsp; */}
                      <Link to={`/editar-interes/${i.id}`}>
                        <Icon name="pencil" color="grey" /> Editar
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                )
              }
            </Table.Body>
          </Table>
        </Grid.Row>
      </Grid>

      <Modal
      open={isModalOpen}
      closeIcon
      onClose={handleCloseModal}
      >
        <Header icon="table" content="Registro del Lecciones" />
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Curso</Table.HeaderCell>
                <Table.HeaderCell>Interés</Table.HeaderCell>
                <Table.HeaderCell>Instructor</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{dataModal.curso}</Table.Cell>
                <Table.Cell>{dataModal.interes}</Table.Cell>
                <Table.Cell>{dataModal.instructor}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          
          <Table>
            <Table.Row>
              {
                dataModal.lecciones.map((i, index) => (
                  <Table.Cell>{index + 1}</Table.Cell>
                ))
              }
            </Table.Row>
            <Table.Row>
              {
                dataModal.lecciones.map((i, index) => (
                  <Table.Cell positive={i === "done"} negative={!i}>
                    <Icon name={i === "done" ? "check" : "close"} />
                  </Table.Cell>
                ))
              }
            </Table.Row>
          </Table>
        </Modal.Content>
      </Modal>
    </Segment>
  );

};

export default ListaIntereses;