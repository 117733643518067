import React, { Component } from "react"
import {
  Segment,
  Form,
  Header,
  Icon,
  Divider,
  Button
} from "semantic-ui-react";
import feathers from "../../../feathers-client";
import swal from "sweetalert";

class EditClass extends Component {
  state = {
    loading: false,
    class: {
      name: ""
    }
  }

  componentDidMount() {
    this.getClass();
  }

  getClass = () => {
    this.setState({ loading: true });
    feathers
      .service("clases")
      .get(this.props.match.params.id)
      .then(res => {
        console.log(res);
        this.setState({ loading: false, class: res });
      })
      .catch(err => {
        console.log(err);
      });
    this.setState({ loading: false });
  }

  handleChange = (e) => {
    this.setState({
      class: {
        ...this.state.class,
        [e.target.name]: e.target.value
      }
    });
  };

  handleSubmit = async () => {
    const class_temp = {...this.state.class}
    delete class_temp.created_at;
    this.setState({ loading: true });
    await feathers
      .service("clases")
      .patch(this.props.match.params.id, class_temp)
      .then(res => {
        console.log(res);
        swal(
          "Clase Editada",
          "La clase se ha editado correctamente",
          "success",
          {timer: 1500}
        );
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        swal(
          "Error",
          "Ha ocurrido un error. Por favor intente mas tarde",
          "error"
        );
        this.setState({ loading: false });
      });
  };

  render() {
    return (
     <Segment loading={this.state.loading}>
       <Header as="h1">
          <Icon name="plus" size="large" />
          <Header.Content>Agregar Clase</Header.Content>
        </Header>
        <Divider />
        <Form style={{ textAlign: "left"}}>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="name"
              label="Nombre de la Clase"
              value={this.state.class.name}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Button primary content="Registrar" onClick={this.handleSubmit} />
        </Form>
     </Segment>
    )
  }
}

export default EditClass;