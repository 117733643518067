import {
  GET_RECORDS_GRUPO,
  UPDATE_RECORDS
} from '../types'

export default(state, action) => {
  switch(action.type) {
    case GET_RECORDS_GRUPO:
      return {
        ...state,
        records: action.payload
      }
    case UPDATE_RECORDS:
      return {
        ...state,
        records: action.payload
      }
    default:
      return state
  }
}