import React, { Component } from "react"
import {
  Segment,
  Form,
  Header,
  Icon,
  Divider,
  Button,
} from "semantic-ui-react";
import feathers from "../../../feathers-client";
import swal from "sweetalert";
import md5 from "md5";

const INITIAL_DATA = {
  full_name: "",
  username: "",
  clase_id: 0,
  rol: "Maestro",
}
const ROLES_DATA = [
  {
    key: 0,
    text: "Maestro",
    value: "Maestro"
  },
  {
    key: 1,
    text: "Director",
    value: "Director"
  }
]

class EditMaestro extends Component {
  state = {
    loading: false,
    loadingPassword: false,
    clasesLoading: true,
    user: INITIAL_DATA,
    clases: [],
    newPassword: {
      password: "",
      confirmPassword: ""
    },
    roles: ROLES_DATA
  }

  componentDidMount() {
    this.getMaestro();
    this.getClases();
  }

  getMaestro = () => {
    this.setState({ loading: true });
    feathers
      .service("users")
      .get(this.props.match.params.id)
      .then(res => {
        this.setState({ loading: false, user: res });
      })
      .catch(err => {
        console.log(err);
      });
    this.setState({ loading: false });
  }

  getClases = () => {
    const clases = feathers.service("clases");
    clases.find({
      query: {
        $limit: 1000
      }
    })
      .then(res => {
        const clases = res.data.map((c, i) => ({
          key: i,
          text: c.name,
          value: c.id
        }))
        this.setState({clases, clasesLoading: false})
      })
  }

  handleChange = (e) => {
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value
      },
    });
  };

  handleChangePass = (e) => {
    this.setState({
      newPassword: {
        ...this.state.newPassword,
        [e.target.name]: e.target.value
      },
    });
  };

  handleChangeSelect = (event, result) => {
    const { name, value } = result || event.target;
    this.setState({
      user: {
        ...this.state.user,
        [name]: value
      }
    });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    const user_temp = {...this.state.user}
    delete user_temp.created_at;
    await feathers
      .service("users")
      .patch(this.props.match.params.id, user_temp)
      .then(res => {
        console.log(res);
        swal(
          "Maestro Editado",
          "El maestro se ha editado correctamente",
          "success",
          {timer: 1500}
        );
        this.setState({
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        swal(
          "Error",
          "Ha ocurrido un error. Por favor intente mas tarde",
          "error"
        );
        this.setState({ loading: false });
      });
  };

  handleSubmitPassword = async () => {
    this.setState({ loadingPassword: true });
    await feathers
      .service("users")
      .patch(this.props.match.params.id, {
        password: md5(this.state.newPassword.password)
      })
      .then(res => {
        swal(
          "Contraseña cambiada",
          "La contraseña se ha cambiado correctamente",
          "success"
        );
        this.setState({
          newPassword: {
            password: "",
            confirmPassword: ""
          }
        })
      })
      .catch(err => {
        swal(
          "Error",
          "Ha ocurrido un error. Por favor intente mas tarde",
          "error"
        );
      });
    this.setState({ loadingPassword: false });
  }

  render() {
    return (
    <div>
      <Segment loading={this.state.loading}>
        <Header as="h1">
          <Icon name="plus" size="large" />
          <Header.Content>Editar Maestro</Header.Content>
        </Header>
        <Divider />
        <Form style={{ textAlign: "left"}}>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="full_name"
              label="Nombre del Maestro"
              value={this.state.user.full_name}
              onChange={this.handleChange}
            />
            <Form.Input
              required
              fluid
              name="username"
              label="Usuario del Maestro"
              value={this.state.user.username}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Select
              required
              fluid
              label="Clase:"
              value={this.state.user.clase_id}
              name="clase_id"
              options={this.state.clases}
              onChange={this.handleChangeSelect}
            />
            <Form.Select
              required
              fluid
              label="Rol:"
              value={this.state.user.rol}
              name="rol"
              options={this.state.roles}
              onChange={this.handleChangeSelect}
            />
          </Form.Group>
          <Button
            disabled={
              this.state.user.full_name === "" ||
              this.state.user.username === "" ||
              this.state.user.password === "" || 
              this.state.user.clase_id === 0
            }
            primary content="Registrar" 
            onClick={this.handleSubmit} 
          />
        </Form>
      </Segment>
      <Segment loading={this.state.loadingPassword}>
        <Header as="h1">
          <Icon name="plus" size="large" />
          <Header.Content>Cambiar Contraseña</Header.Content>
        </Header>
        <Divider />
        <Form style={{ textAlign: "left"}}>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              type="password"
              name="password"
              label="Nueva contraseña:"
              value={this.state.newPassword.password}
              onChange={this.handleChangePass}
            />
            <Form.Input
              required
              fluid
              type="password"
              name="confirmPassword"
              label="Confirmar contraseña:"
              value={this.state.newPassword.confirmPassword}
              onChange={this.handleChangePass}
            />
          </Form.Group>
          <Button 
            disabled={
              this.state.newPassword.password === "" ||
              this.state.newPassword.confirmPassword === "" ||
              this.state.newPassword.password !== this.state.newPassword.confirmPassword
            }
            primary 
            content="Guardar" 
            onClick={this.handleSubmitPassword}
          >

          </Button>
        </Form>
      </Segment>
    </div>
    )
  }
}

export default EditMaestro;
