import React, { useReducer } from 'react';
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";
import {
  SAVE_USER_LOGGED,
  SAVE_TOKEN_LOGGED
} from '../types'

const AuthState = props => {
  const initialState = {
    user: {},
    token: null
  }

  const [state, dispatch] = useReducer(AuthReducer, initialState)

  // POST - login
    // TODO

  // save user logged
  const saveUserLogged = user => {
    console.log("user =>",user);
    dispatch({ type: SAVE_USER_LOGGED, payload: user })
  }

  // save token logged
  const saveTokenLogged = token => {
    console.log("token =>", token);
    dispatch({ type: SAVE_TOKEN_LOGGED, payload: token })
  }

  return <AuthContext.Provider
    value={{
      user: state.user,
      token: state.token,
      saveUserLogged,
      saveTokenLogged
    }}
  >
    {props.children}
  </AuthContext.Provider>
}

export default AuthState;
