import React, { useReducer, useEffect } from 'react';
import CatalogosContext from './catalogosContext';
import CatalogosReducer from './catalogosReducer';
import feathers from "../../feathers-client"
import {
  GET_STUDENTS,
  GET_CLASES,
  GET_MIEMBRO,
  GET_MIEMBROS,
  SET_LOADING,
  SET_CLASE_SELECTED,
  GET_INTERESES,
  GET_INTERES
} from "../types"
import moment from "moment";
import _ from 'lodash';
import localforage from 'localforage';
import { CURSOS_ARRAY } from '../../utils/inital-data-interes'

const CatalogosState = props => {
  const initialState = {
    loading: true,
    students: [],
    clases: [],
    miembros: [],
    intereses: [],
    miembro: null,
    claseSelected: 0,
    interes: null
  }

  const [state, dispatch] = useReducer(CatalogosReducer, initialState)

  useEffect(() => {
    (async () => {
      const claseSelected = await localforage.getItem('claseSelected')
      setClaseSelected(claseSelected);
    })();
  }, [])

  // set loading
  const setLoading = (loading) => {
    dispatch({
      type: SET_LOADING,
      payload: loading
    })
  }

  //GET students
  const getStudentsByClase = (claseId) => {
    const students = feathers.service("students");
    students.find({
      query: {
        clase_id: claseId
      }
    })
    .then(res => {
      const students = res.data.map((s, i) => ({
        key: i,
        text: s.full_name,
        value: s.id
      }))
      dispatch({
        type: GET_STUDENTS,
        payload: students
      })
    })
    .catch(err => console.log("ERROR GET_STUDENTS =>", err))
  };

  // set claseSelected
  const setClaseSelected = (claseSelected) => {
    localforage.setItem("claseSelected", claseSelected)
    dispatch({
      type: SET_CLASE_SELECTED,
      payload: claseSelected
    })
  }

  // GET clases
  const getClases = (isDirector = false, clase_id = 0) => {
    const clases = feathers.service("clases");
    let query = {}
    if(isDirector) {
      query = {
        $limit: 1000
      }
    } else {
      query = {
        $limit: 1000,
        id: clase_id
      }
    }
    clases.find({
      query
    })
    .then(res => {
      const clases = res.data.map((c, i) => ({
        key: i,
        text: c.name,
        value: c.id
      }))
      dispatch({
        type: GET_CLASES,
        payload: clases
      })
    })
  }

  //GET Miembro GP
  const getMiembro = (id) => {
    feathers
      .service("students-grupo")
      .get(id)
      .then(res => {
        console.log("miembro GP =>",res);
        const tags = JSON.parse(res.tags);
        
        dispatch({
          type: GET_MIEMBRO,
          payload: {
            ...res,
            type: tags[0],
            age: tags[1],
            birthdate: moment(res.birthdate).format("DD/MM/YYYY")
          }
        })
      })
      .catch(err => console.log("ERROR => GET miembro : ", err));
  }

  // GET Miembros GP
  const getMiembros = (claseId) => {
    dispatch({
      type: SET_LOADING,
      payload: true
    })
    const clases = feathers.service("students-grupo");
    clases.find({
      query: {
        clase_id: claseId,
        $limit: 1000
      }
    })
      .then(res => {
        let miembros = res.data.map(m => {
          const tags = JSON.parse(m.tags);
          return {
            ...m,
            type: tags[0],
            age: tags[1]
          }
        });
        miembros = _.sortBy(miembros, ['type', 'age']);
        dispatch({
          type: GET_MIEMBROS,
          payload: miembros
        })
      })
      .catch(err => console.log("ERROR => GET miembros : ", err));
  }

  // GET Intereses
  const getIntereses = (claseId) => {
    setLoading(true)
    const intereses = feathers.service("interest-endpoint");
    intereses.find({
      query: {
        clase_id: claseId,
        $limit: -1
      }
    })
    .then(res => {
      const intereses_array = res.map(i => ({
        ...i,
        curso: CURSOS_ARRAY[i.curso]
      }))
      dispatch({
        type: GET_INTERESES,
        payload: intereses_array
      })
    })
  }

  // GET Interes
  const getInteres = (id) => {
    setLoading(true)
    feathers
      .service("interests")
      .get(id)
      .then(res => {
        const translator = {
          'interes' : 'interest',
          'telefono' : 'phone',
          'direccion' : 'address',
          'edad' : 'age',
          'curso' : 'course' 
        }
        Object.keys(translator).forEach(element => res[element] = res[translator[element]]);
        Object.keys(translator).forEach(element => delete res[translator[element]])
        dispatch({
          type: GET_INTERES,
          payload: res
        })
      })
      .catch(err => console.log("ERROR => GET interes : ", err));
  }

  return <CatalogosContext.Provider
    value={{
      students: state.students,
      loading: state.loading,
      clases: state.clases,
      miembros: state.miembros,
      miembro: state.miembro,
      claseSelected: state.claseSelected,
      intereses: state.intereses,
      interes: state.interes,
      getClases,
      getMiembros,
      setLoading,
      getMiembro,
      getStudentsByClase,
      setClaseSelected,
      getIntereses,
      getInteres
    }}
  >
    { props.children }
  </CatalogosContext.Provider>

}

export default CatalogosState