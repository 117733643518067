import {
  GET_CLASES,
  GET_MIEMBROS,
  GET_MIEMBRO,
  GET_STUDENTS,
  SET_CLASE_SELECTED,
  GET_INTERESES,
  GET_INTERES
} from "../types"

export default (state, action) => {
  switch(action.type) {
    case GET_CLASES: 
      return {
        ...state,
        clases: action.payload,
        // loading: false
      }
    case GET_MIEMBROS:
      return {
        ...state,
        miembros: action.payload,
        loading: false
      }
    case GET_MIEMBRO:
      return {
        ...state,
        miembro: action.payload,
        loading: false,
      }
    case GET_STUDENTS:
      return {
        ...state,
        students: action.payload
      }
    case SET_CLASE_SELECTED: 
      return {
        ...state,
        claseSelected: action.payload
      }
    case GET_INTERESES:
      return {
        ...state,
        intereses: action.payload,
        loading: false
      }
    case GET_INTERES:
      return {
        ...state,
        interes: action.payload,
        loading: false
      }
    default:
      return state
  }
}