import React, { Component } from "react";
import feathers from "../../../feathers-client";
import {
  Segment,
  Header,
  Icon,
  Divider,
  Form,
  Button
} from "semantic-ui-react";
import swal from "sweetalert";

class EditDatosGrupo extends Component {

  state = {
    loading: true,
    datos: {},
    alumnos: []
  }

  componentDidMount() {
    this.getDatosGrupo();
    this.getAlumnos()
  }

  getDatosGrupo = async () => {
    const datos = feathers.service("groups")
    await datos.find({ query: { clase_id: this.props.match.params.id } })
    .then(res => {
      this.setState({ datos: res.data[0], loading: false })
    })
    .catch(err => {
      console.log(err);
    })
  }

  getAlumnos = (claseId) => {
    const clases = feathers.service("students");
    clases.find({
      query: {
        clase_id: this.props.match.params.id,
        $limit: 1000
      }
    })
      .then(res => {
        const alumnos = res.data.map((a, i) => ({
          key: i,
          text: a.full_name,
          value: a.id
        }))
        alumnos.push({
          key: res.data.length,
          text: "N/A",
          value: 0
        })
        this.setState({ alumnos })
      })
  }

  handleChange = (e) => {
    this.setState({
      datos: {
        ...this.state.datos,
        [e.target.name]: e.target.value
      },
    });
  };

  handleChangeSelect = (event, result) => {
    const { name, value } = result || event.target;
    this.setState({
      datos: {
        ...this.state.datos,
        [name]: value
      }
    });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    const datos_temp = {...this.state.datos}
    delete datos_temp.clase;
    delete datos_temp.director_canto_name;
    delete datos_temp.confraternizador_name;
    delete datos_temp.director_oracion_name;
    delete datos_temp.atencion_niños_name;
    delete datos_temp.estudio_biblia_name;
    delete datos_temp.actividad_misionera_name;

    await feathers
      .service("groups")
      .patch(datos_temp.id, datos_temp)
      .then(res => {
        // console.log(res);
        swal(
          "Datos Editados",
          "Los datos se han editado correctamente",
          "success",
          {timer: 1500}
        );
        this.setState({
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        swal(
          "Error",
          "Ha ocurrido un error. Por favor intente mas tarde",
          "error"
        );
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, datos, alumnos } =  this.state;

    return (
      <Segment loading={loading} className="segment-edit-datos">
        <Header as="h1">
          <Icon name="edit" size="large" />
          <Header.Content>Editar datos del Grupo Pequeño</Header.Content>
        </Header>
        <Divider />

        <Form style={{ textAlign: "left"}}>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="nombre"
              label="Nombre del Grupo Pequeño"
              value={datos.nombre}
              onChange={this.handleChange}
            />
            <Form.Input
              required
              fluid
              name="lema"
              label="Lema"
              value={datos.lema}
              onChange={this.handleChange}
            />
            <Form.Input
              required
              fluid
              name="blanco"
              label="Blanco"
              value={datos.blanco}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="grito"
              label="Grito de Victoria"
              value={datos.grito}
              onChange={this.handleChange}
            />
            <Form.Input
              required
              fluid
              name="canto"
              label="Canto Tema"
              value={datos.canto}
              onChange={this.handleChange}
            />
            <Form.Input
              required
              fluid
              name="texto"
              label="Texto Bíblico"
              value={datos.texto}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="dia"
              label="Día de Reunión"
              value={datos.dia}
              onChange={this.handleChange}
            />
            <Form.Input
              required
              fluid
              name="hora"
              label="Hora de Reunión"
              value={datos.hora}
              onChange={this.handleChange}
            />
            <Form.Input
              required
              fluid
              name="lugar"
              label="Lugar de Reunión"
              value={datos.lugar}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Divider />
          
          <Form.Group widths="equal" className="form-edit-datos">
            <Form.Select
              required
              fluid
              label="Director de Cantos"
              value={datos.director_canto}
              name="director_canto"
              options={alumnos}
              onChange={this.handleChangeSelect}
            />
            <Form.Select
              required
              fluid
              label="Confraternizador"
              value={datos.confraternizador}
              name="confraternizador"
              options={alumnos}
              onChange={this.handleChangeSelect}
            />
            <Form.Select
              required
              fluid
              label="Director de Oración"
              value={datos.director_oracion}
              name="director_oracion"
              options={alumnos}
              onChange={this.handleChangeSelect}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Select
              required
              fluid
              label="Atención de Niños"
              value={datos.atencion_niños}
              name="atencion_niños"
              options={alumnos}
              onChange={this.handleChangeSelect}
            />
            <Form.Select
              required
              fluid
              label="Estudio de la Biblia"
              value={datos.estudio_biblia}
              name="estudio_biblia"
              options={alumnos}
              onChange={this.handleChangeSelect}
            />
            <Form.Select
              required
              fluid
              label="Actividad Misionera"
              value={datos.actividad_misionera}
              name="actividad_misionera"
              options={alumnos}
              onChange={this.handleChangeSelect}
            />
          </Form.Group>

          <Button
            disabled={
              datos.nombre === "" ||
              datos.lema === "" ||
              datos.blanco === "" ||
              datos.grito === "" ||
              datos.canto === "" ||
              datos.texto === "" ||
              datos.dia === "" ||
              datos.hora === "" ||
              datos.lugar === ""
            }
            primary
            content="Registrar" 
            onClick={this.handleSubmit} 
          />

        </Form>
      </Segment>
    )
  }
}

export default EditDatosGrupo;