import React, {createContext} from 'react';
import { GroupProvider } from './GroupContext/GroupContext'
import ReporteState from './ReporteContext/ReporteState'
import AuthState from './Auth/AuthState'
import CatalogosState from './CatalogosContext/CatalogosState'
import ListaGrupoState from './ListaGrupoContext/ListaGrupoState';

const GeneralContext = createContext();

const { Consumer, Provider } = GeneralContext;

export function GeneralProvider({children}){
    return (
    <Provider>
        <GroupProvider>
            <ReporteState>
                <AuthState>
                    <CatalogosState>
                        <ListaGrupoState>
                            {children}
                        </ListaGrupoState>
                    </CatalogosState>
                </AuthState>
            </ReporteState>
        </GroupProvider>
    </Provider>
    )
}

