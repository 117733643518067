import React, { useContext, useState } from "react";
import { Grid, Segment, Image, Form, Button } from "semantic-ui-react";
import feathers from "../../feathers-client";
import swal from "sweetalert";
import jwtDecode from "jwt-decode";
import AuthContext from '../../Context/Auth/authContext'

const Login = () =>  {
  // const history = useHistory();
  const authContext = useContext(AuthContext);
  const { saveUserLogged, saveTokenLogged } = authContext;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: ""
  });

  const handleChange = e => {
    setData({...data, [e.target.name]: e.target.value});
  };

  const handleLogin = () => {
    setLoading(true);
    const { username, password } = data;
    feathers
      .service("tokenapi")
      .create({
        username,
        password
      })
      .then(res => {
        console.log(res);
        if (res.status === 403) {
          swal(
            "Error",
            "La combinación usario/contraseña no existe. Intente de nuevo.",
            "error"
          );
          setLoading(false)
        } else {
          const token = jwtDecode(res.token)[0];
          localStorage.setItem(
            "token",
            JSON.stringify(token)
          );
          localStorage.setItem("tokenjwt", res.token);
          saveUserLogged(JSON.stringify(token))
          saveTokenLogged(res.token)
          setLoading(false)

          if(token.rol === "Director") {
            window.location = "/alumnos";
            // history.push("/alumnos")
          } else if (token.rol === "Maestro") {
            window.location = "/pase-lista";
            // history.push("/pase-lista")
          }
        }
      })
      .catch(err => {
        console.log(err);
        swal(
          "Error",
          "Se produjo un error al realizar la operación. Intente más tarde.",
          "error"
        );
        setLoading(false)
      });
  };

  return (
    <Grid>
      <Grid.Row centered columns={1}>
        <Grid.Column width={8}>
          <Image src="/iasd_negro.png" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered columns={1}>
        <Grid.Column width={8}>
          <Segment loading={loading}>
            <Form>
              <Form.Input
                label="Usuario"
                placeholder="Usuario"
                fluid
                value={data.username}
                name="username"
                onChange={handleChange}
              />
              <Form.Input
                label="Contraseña"
                placeholder="Contraseña"
                fluid
                type="password"
                value={data.password}
                name="password"
                onChange={handleChange}
              />
              <Button
                disabled={
                  data.username === "" || data.password === ""
                }
                primary
                content="Ingresar"
                onClick={handleLogin}
              />
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default Login;
