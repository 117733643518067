import React, { useState, useContext, useEffect } from 'react';
import {
  Segment,
  Form,
  Header,
  Icon,
  Divider,
  Button,
} from "semantic-ui-react";
import feathers from "../../feathers-client";
import swal from "sweetalert";
import CatalogosContext from '../../Context/CatalogosContext/catalogosContext'
import {
  INITIAL_DATA,
  TYPE_DATA,
  AGE_DATA
} from '../../utils/initial-data-miembros'

const CreateMiembroGP = () => {
  const catalogosContext = useContext(CatalogosContext);
  const { loading, clases, setLoading, getClases } = catalogosContext;

  const [newMiembro, setNewMiembro] = useState(INITIAL_DATA)

  useEffect(() => {
    getClases(true);
  }, []);

  useEffect(() => {
    if(clases.length !== 0)
      setNewMiembro({
        ...newMiembro,
        clase_id: clases[0].value
      });
  }, [clases]);

  const handleChange = (e) => {
    setNewMiembro({
      ...newMiembro,
      [e.target.name]: e.target.value
    })
  };

  const handleChangeSelect = (event, result) => {
    const { name, value } = result || event.target;
    setNewMiembro({
      ...newMiembro,
      [name]: value
    })
  };

  const handleSubmit = () => {
    setLoading(true)
    const tags = [newMiembro.type, newMiembro.age]
    const newMiembroTemp = {...newMiembro, tags: JSON.stringify(tags)};
    delete newMiembroTemp.type;
    delete newMiembroTemp.age;
    console.log(newMiembroTemp);
    feathers
      .service("students-grupo")
      .create(newMiembroTemp)
      .then(_ => {
        swal({
          icon: "success",
          title: "Miembro Agregado",
          text: "El miembro se ha agregado correctamente",
          timer: 1500
        });
        setNewMiembro(INITIAL_DATA);
        setLoading(false);
      })
      .catch(err => {
        console.log("ERROR CREATE MIEMBROGP => ",err);
        swal(
          "Error",
          "Ha ocurrido un error. Por favor intente mas tarde",
          "error"
        );
        setLoading(false);
      });
  }

  return (
    <Segment loading={loading}>
      <Header as="h1">
        <Icon name="plus" size="large" />
        <Header.Content>Agregar Miembro</Header.Content>
      </Header>

        <Divider />

        <Form style={{ textAlign: "left"}}>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="full_name"
              label="Nombre del Alumno"
              value={newMiembro.full_name}
              onChange={handleChange}
            />
            <Form.Select
              required
              fluid
              label="Clase:"
              value={newMiembro.clase_id}
              name="clase_id"
              options={clases}
              onChange={handleChangeSelect}
            />
            <Form.Input
              required
              fluid
              name="birthdate"
              label="Fecha de Nacimiento"
              value={newMiembro.birthdate}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Select
              required
              fluid
              label="Miembro / Visita:"
              value={newMiembro.type}
              name="type"
              options={TYPE_DATA}
              onChange={handleChangeSelect}
            />
            <Form.Select
              required
              fluid
              label="Edad:"
              value={newMiembro.age}
              name="age"
              options={AGE_DATA}
              onChange={handleChangeSelect}
            />
          </Form.Group>

          <Button
            disabled={
              newMiembro.full_name === "" ||
              newMiembro.clase_id === 0
            }
            primary content="Registrar" 
            onClick={handleSubmit} 
          />

        </Form>
    </Segment>
  )
};

export default CreateMiembroGP;