import React, { Component } from "react";
import {
  Segment,
  Grid,
  Table,
  Header,
  Divider,
  Icon,
  Select,
  Form
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import feathers from "../../feathers-client";
import swal from "sweetalert";
import _ from "lodash"
import localforage from "localforage";

class ListaAlumnos extends Component {
  state = {
    alumnos: [],
    clases: [],
    claseSelected: 0,
    loading: true
  }

  componentDidMount() {
    localforage.getItem('claseSelected').then((res) => {
      this.getClases(res)
    })
  }

  getClases = (claseDefault = false) => {
    const clases = feathers.service("clases");
    clases.find({
      query: {
        $limit: 1000
      }
    })
      .then(res => {
        const clases = res.data.map((c, i) => ({
          key: i,
          text: c.name,
          value: c.id
        }))
        const claseSelected = claseDefault ? claseDefault : clases[0].value
        this.setState({
          clases, 
          clasesLoading: false,
          claseSelected
        })
        this.getAlumnos(claseSelected)
      })
  }

  getAlumnos = (claseId) => {
    const clases = feathers.service("students");
    clases.find({
      query: {
        clase_id: claseId,
        $limit: 1000
      }
    })
      .then(res => {
        const alumnos_sorted = _.sortBy(res.data, "rol").reverse()
        this.setState({ alumnos: alumnos_sorted, loading: false })
      })
  }

  handleDelete = async (Id) => {
    this.setState({ loading: true })
    await swal({
      title: "¿Seguro desea eliminar el alumno?",
      text:
        "Esta operación es irreversible",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async willDelete => {
      if (willDelete) {
        await feathers
          .service("students")
          .remove(Id)
          .then(res => {
            swal("Alumno eliminado correctamente", { icon: "success", timer: 2000 });
            this.getAlumnos(this.state.claseSelected);
          })
          .catch(err => {
            console.log(err);
            swal("Ocurrió un error. Intente más tarde", { icon: "error" });
          });
      }
    });
    this.setState({ loading: false })
  };

  handleChangeClass = (event, result) => {
    this.setState({ loading: true })
    const { name, value } = result || event.target;
    this.setState({ [name]: value })
    this.getAlumnos(value)
    localforage.setItem("claseSelected", value)

  }

  render() {

    return(
      <Segment loading={this.state.loading}>
        <Grid>
          <Grid.Column width={12}>
            <Header as="h1">
              <Icon name="list" />
              <Header.Content>Listado de Alumnos</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Link to="crear-alumno">
            <Icon name='plus' size="big" color="green"/>
            </Link>
          </Grid.Column>
        </Grid>
         
        <Divider />

        <Grid style={{ padding: "0 20px"}}>
          <Grid.Row columns={4} textAlign="left">
            <Grid.Column>
              <Form.Field>
                <Select
                  label="Clase"
                  value={this.state.claseSelected}
                  options={this.state.clases}
                  name="claseSelected"
                  onChange={this.handleChangeClass}
                />
              </Form.Field>
              
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Rol</Table.HeaderCell>
                  <Table.HeaderCell>Acciones</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.alumnos.map((a, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{a.full_name}</Table.Cell>
                    <Table.Cell>{a.rol}</Table.Cell>
                    <Table.Cell>
                      <Link to="#" onClick={() => this.handleDelete(a.id)}>
                        <Icon name="trash" color="grey" /> Eliminar
                      </Link>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Link to={`/editar-alumno/${a.id}`}>
                        <Icon name="pencil" color="grey" /> Editar
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }
}

export default ListaAlumnos;