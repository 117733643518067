import React from 'react';
import { Segment, Grid, Header, Icon, Table, Button } from "semantic-ui-react";
import feathers from "../../../feathers-client";


export default function ReportePorClase({Loading, GeneralReport, QuarterWeek, Quarter}){

    async function handleExcel(){

      let json = GeneralReport.map( r => {
        return {
          "Clase": r.clase_name,
          "Asistencia": Math.round(r.sabaths[QuarterWeek-1]) + "%"
        }
      })
      
      const excel = feathers.service("json2xls");
      await excel.find({
        query: {
          json
        }
      })
      .then(res => {

        setTimeout(_ => window.open(
          `${process.env.REACT_APP_IP_API}/${res.data.split("/")[1]}`,
          "_blank"
        ), 500  )
        
      })
      .catch(err => {
        console.log(err);
      })
    }
    
    return (
        <Segment loading={Loading}>
            <Grid>
                <Grid.Column width={12}>
                <Header as="h1">
                    <Icon color="red" name="file excel" />
                    <Header.Content>Reporte de Grupos</Header.Content>
                </Header>
                </Grid.Column>
                <Grid.Column width={4}>
                <Button
                icon="file excel"
                positive
                content="Exportar"
                onClick={handleExcel}
                />
            </Grid.Column>
            </Grid>
            <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Clase</Table.HeaderCell>
                    <Table.HeaderCell>
                      <Icon name="clock"/>
                      Asistencia
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                {GeneralReport.map( (r, i) => {
                  return (
                    <Table.Row key={i}>

                    <Table.Cell >{r.clase_name}</Table.Cell>

                     
                    <Table.Cell  
                    positive={r.sabaths[QuarterWeek-1]>=80} 
                    warning={r.sabaths[QuarterWeek-1]>=60&&r.sabaths[QuarterWeek-1]<80} 
                    negative={r.sabaths[QuarterWeek-1]<60}>
                        {Math.round(r.sabaths[QuarterWeek-1]) + "%"}
                    </Table.Cell>
                        
                        
                     
                    </Table.Row>
                  )
                })}
               
            </Table.Body>
            </Table>
        </Segment>
    )
}