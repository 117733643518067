import React, { createContext, useState } from 'react';

const GroupContext = createContext();

const { Consumer, Provider } = GroupContext;

export function GroupProvider({children}){

    const [Objetivos, setObjetivos] = useState(null)


    const ProviderProps = {
        setObjetivos,
        Objetivos
    }

    return (
        <Provider value={ProviderProps}>
            {children}
        </Provider>
    )
}

export { GroupContext };