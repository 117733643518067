import React, {useState, useEffect} from 'react'
import feathers from "../../../feathers-client";
import { CTable } from 'react-minimalist-table'
import { Segment, Form, Dropdown } from 'semantic-ui-react'

const now = new Date().getUTCFullYear();    

const YEARS = Array(now - (now - 20)).fill('').map((v, idx) => now - idx).map(v => {
    return {
        key: v,
        text: v,
        value: v
    }
});


const useData = (START_YEAR, END_YEAR, CLASS, setLoading) => {

    const [Data, setData] = useState([{Loading: ""}])

    useEffect(() => {

        setLoading(true)
        const taskreport = feathers.service("taskreport")

        taskreport.find({
            query: {
                START_YEAR, END_YEAR, CLASS
            }
        }).then(data => {
            setData(data);
            setLoading(false)
        }).catch( error => {
            setLoading(false)
            console.log(error)
        })

        
        
    }, [START_YEAR, END_YEAR, CLASS])

    return { Data }
}

const useCLASSES = () => {

    const token = JSON.parse(localStorage.getItem("token"));

    

    const [CLASS, setCLASS] = useState(token ? token.clase_id : null);
    const [CLASSES, setCLASSES] = useState([])

    useEffect(() => {
        const clases = feathers.service("clases")
    
        clases.find({
            query: {
                $limit: '-1'
            }
        }).then((data) => {
            const OPTIONS = data.map((v, i) => {
                return {
                    key: i,
                    text: v.name,
                    value: v.id
                }
            })
            setCLASSES(OPTIONS)
        }).then(error => console.error(error))
        
    }, [])

    return {
        CLASSES,
        CLASS,
        setCLASS
    }
}

export default function ReporteObjetivos() {

    const [START_YEAR, setSTART_YEAR] = useState((new Date().getFullYear()) - 1);
    const [END_YEAR, setEND_YEAR] = useState((new Date().getFullYear()) );
    
    const [Loading, setLoading] = useState(false);
   
    const {CLASSES, CLASS, setCLASS} = useCLASSES();

    const { Data } = useData(START_YEAR, END_YEAR, CLASS, setLoading);


    return (
        <Segment loading={Loading}>
            <Segment>
                <Form>
                    <Form.Group widths='equal' inline>
                        <div style={{flex: 1}}>
                            <Dropdown  search selection placeholder="Clase" options={CLASSES} value={CLASS} onChange={(_, {value}) => {
                                setCLASS(value)
                            }} />
                        </div>
                        <div style={{flex: 1}}>
                            <Dropdown search selection placeholder="Año inicio" options={YEARS} value={START_YEAR} onChange={(_, {value}) => {
                                setSTART_YEAR(value)
                                setEND_YEAR(value + 1)
                            }} />
                        </div>
                        <div style={{flex: 1}}>
                            <Dropdown search selection options={[{key: END_YEAR, text: END_YEAR, value: END_YEAR}]} placeholder="Año fin" value={END_YEAR} disabled />
                        </div>
                    </Form.Group>
                </Form>
            </Segment>
            <CTable 
                caption={"Reporte anual"} 
                headerColor={"#f9fafb"}
                headerTextColor={'black'}
                data={Data}
                conditionalCellStyle={
                    [
                    {
                        columns: ['Oct', 'Nov', 'Dic', 'Ene', 'Feb', 'Mar', 'Abr', 'May','Jun', 'Jul', 'Ago', 'Sep'],
                        styleTrue: {
                            color: 'green'
                        },
                        styleFalse: {
                            color: 'orange'
                        },
                        defaultStyle: {
                            color: 'red'
                        },
                        validation: function(value){
                            let numericValue = value.substr(0, value.indexOf("%"));
                            numericValue = +(numericValue)
                            if(numericValue > 80) return true
                            else if (numericValue <= 80 && numericValue > 60){
                                return false
                            }
                            
                        }
                    }
                    ]
                }

            />
        </Segment>
    )
}
