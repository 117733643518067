export const INITIAL_DATA = {
  clase: 0, 
  instructor: 0,
  interes: "",
  direccion: "",
  telefono: "",
  edad: "",
  curso: "fe-de-jesus",
};

export const CURSOS = [
  {
    key: 0,
    text: "Fé de Jesús",
    value: "fe-de-jesus"
  },
  {
    key: 1,
    text: "Busquemos en la Biblia",
    value: "busquemos-en-la-biblia"
  },
]

export const CURSOS_ARRAY = {
  "fe-de-jesus": "Fé de Jesús",
  "busquemos-en-la-biblia": "Busquemos en la Biblia",
}