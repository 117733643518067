import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import Content from "./Global/Content";
import Navbar from "./Navbar"
import './App.css';

class App extends Component {

  state = {
    userLogged: "",
    activeItem: "",
    isLogged: false,
    isAdmin: false,
    isTeacher: false,
  };

  componentDidMount() {
    const token = JSON.parse(localStorage.getItem("token"));
    // console.log(token);
    if (token !== null) {
      this.setState({
        userLogged: token.full_name,
        isLogged: true
      });
      if (token.rol === "Director") {
        this.setState({ isAdmin: true });
      } else if (token.rol === "Maestro") {
        this.setState({ isTeacher: true });
      }
    }
  }

  render() {
    const { children } = this.props;

    return(
      <div className="App">
        <Container>
        {
          this.state.isLogged ? (
            <Navbar 
              userLogged={this.state.userLogged} 
              isAdmin={this.state.isAdmin}
              isTeacher={this.state.isTeacher}
            >
              <Content body={children} />
            </Navbar>
          )
           : (
            <Content body={children} />
          )
        }
        
        </Container>
      </div>
    )
  };
}

export default App;
