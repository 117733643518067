import React, { useState, useEffect, useContext } from 'react';
import { 
  Segment,
  Header,
  Icon,
  Divider,
  Form,
  Button
 } from 'semantic-ui-react';
import swal from "sweetalert";

import CatalogosContext from '../../Context/CatalogosContext/catalogosContext';
import { INITIAL_DATA, CURSOS } from '../../utils/inital-data-interes';
import feathers from '../../feathers-client';

const EditInteres = (props) => {
  const catalogosContext = useContext(CatalogosContext);
  const { clases, students, interes, loading, setLoading, getInteres, getClases, getStudentsByClase } = catalogosContext;

  const [newInteres, setNewInteres] = useState(INITIAL_DATA)

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    getClases(token.rol === "Director", token.clase_id);
    getInteres(props.match.params.id)
  }, [])

  useEffect(() => {
    if(interes) {
      setNewInteres(interes)
      getStudentsByClase(interes.clase)
    }
  }, [interes])

  const handleChange = (e) => {
    setNewInteres({
      ...newInteres,  
      [e.target.name]: e.target.value
    })
  };

  const handleChangeSelect = (event, result) => {
    const { name, value } = result || event.target;
    setNewInteres({
      ...newInteres,
      [name]: value
    })
    if(name === "clase") {
      getStudentsByClase(value);
    }
  };

  const handleSubmit = () => {
    console.log(newInteres);
    setLoading(true)
    feathers
      .service("interest-endpoint")
      .patch(props.match.params.id, newInteres)
      .then(res => {
        swal({
          icon: "success",
          title: "Interés guardado",
          text: "El interés se ha editado correctamente",
          timer: 1500
        })
        
        setLoading(false)
      })
      .catch(err => {
        console.log("ERROR Patch Interés =>", err);
        swal(
          "Error",
          "Ha ocurrido un error. Por favor intente mas tarde",
          "error"
        );
        setLoading(false);
      });
  }

  return (
    <Segment loading={loading}>

      <Header as="h1">
        <Icon name="plus" size="large" />
        <Header.Content>Alta Interés</Header.Content>
      </Header>

      <Divider />

      <Form style={{ textAlign: "left"}}>
        <Form.Group widths="equal">
          <Form.Input
            required
            fluid
            name="interes"
            label="Nombre del Interés"
            value={newInteres.interes}
            onChange={handleChange}
          />
          <Form.Input
            required
            fluid
            name="direccion"
            label="Dirección del Interés"
            value={newInteres.direccion}
            onChange={handleChange}
          />
          <Form.Input
            required
            fluid
            name="telefono"
            label="Teléfono del Interés"
            value={newInteres.telefono}
            onChange={handleChange}
          />
          <Form.Input
            required
            fluid
            name="edad"
            label="Edad del Interés"
            value={newInteres.edad}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Select
            required
            fluid
            label="Clase"
            value={newInteres.clase}
            name="clase"
            options={clases}
            onChange={handleChangeSelect}
          />
          <Form.Select
            required
            fluid
            label="Instructor"
            value={newInteres.instructor}
            name="instructor"
            options={students}
            onChange={handleChangeSelect}
          />
          <Form.Select
            required
            fluid
            label="Curso"
            value={newInteres.curso}
            name="curso"
            options={CURSOS}
            onChange={handleChangeSelect}
          />

        </Form.Group>

        <Button
          disabled={
            newInteres.interes === "" ||
            newInteres.clase === 0 ||
            newInteres.instructor === 0
          }
          primary content="Actualizar" 
          onClick={handleSubmit} 
        />
      </Form>

    </Segment>
  )
};

export default EditInteres;