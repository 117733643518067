export const GET_REPORTE_SEMAFORO = "GET_REPORTE_SEMAFORO";
export const SAVE_USER_LOGGED = "SAVE_USER_LOGGED";
export const SAVE_TOKEN_LOGGED = "SAVE_TOKEN_LOGGED";
export const SET_LOADING = "SET_LOADING";
export const GET_CLASES = "GET_CLASES";
export const GET_MIEMBRO = "GET_MIEMBRO";
export const GET_MIEMBROS = "GET_MIEMBROS";
export const SAVE_MIEMBRO = "CREATE_MIEMBRO";
export const EDIT_MIEMBRO = "EDIT_MIEMBRO";
export const GET_RECORDS_GRUPO = "GET_RECORDS_GRUPO";
export const SAVE_RECORD = "SAVE_RECORD";
export const UPDATE_RECORDS = "UPDATE_RECORDS";
export const GET_STUDENTS = "GET_STUDENTS";
export const GET_REPORTE_SEMAFORO_GP = "GET_REPORTE_SEMAFORO_GP";
export const SET_CLASE_SELECTED = "SET_CLASE_SELECTED";
export const GET_INTERESES = "GET_INTERESES";
export const GET_INTERES = "GET_INTERES";
