import React, { Component } from "react";
import feathers from "../../feathers-client";
import {
  Segment,
  Grid,
  Table,
  Header,
  Divider,
  Icon,
  Form,
  Select
} from "semantic-ui-react"
import { Link } from "react-router-dom"
import localforage from "localforage";

const keysDatos = {
  "nombre": "Nombre del Grupo Pequeño",
  "lema": "Lema",
  "blanco": "Blanco",
  "grito": "Grito de Victoria",
  "canto": "Canto Tema",
  "texto": "Texo Bíblico",
  "dia": "Día de Reunión",
  "hora": "Hora de Reunión",
  "lugar": "Lugar de Reunión",
}

const keysNombramientos = {
  "director_canto_name": "Director de Canto",
  "confraternizador_name": "Confraternizador",
  "director_oracion_name": "Director de Oración",
  "atencion_niños_name": "Atención de Niños",
  "estudio_biblia_name": "Estudio de la Biblia",
  "actividad_misionera_name": "Actividad Misionera",

}

class DatosGrupo extends Component {

  state = {
    loading: true,
    datos: {},
    claseSelected: 0,
    clases: [],
  }

  componentDidMount() {
    const token = JSON.parse(localStorage.getItem("token"));
    localforage.getItem('claseSelected').then((res) => {
      console.log("clase selected from lf =>", res);
      this.getClases(token.rol === "Director", token.clase_id, res)
    })
  }

  getClases = (isDirector, clase_id, claseDefault = false) => {
    const clases = feathers.service("clases");
    let query = {}
    if(isDirector) {
      query = {
        $limit: 1000
      }
    } else {
      query = {
        $limit: 1000,
        id: clase_id
      }
    }
    clases.find({
      query
    })
    .then(res => {
      const clases = res.data.map((c, i) => ({
        key: i,
        text: c.name,
        value: c.id
      }))
      const claseSelected = !isDirector ? clase_id : (claseDefault ? claseDefault : clases[0].value)
      this.setState({
        clases, 
        clasesLoading: false,
        claseSelected
      })
      this.getDatosGrupo(claseSelected);
    })
  }

  getDatosGrupo = async (clase_id) => {
    const datos = feathers.service("groups")
    await datos.find({ query: { clase_id } })
    .then(res => {
      console.log(res);
      this.setState({ datos: res.data[0], loading: false })
    })
    .catch(err => {
      console.log(err);
    })
  }

  handleChangeClass = (event, result) => {
    this.setState({ loading: true })
    const { name, value } = result || event.target;
    console.log(name, value);
    this.setState({ [name]: value,  })
    this.getDatosGrupo(value)
    localforage.setItem("claseSelected", value)
  }

  render() {
    const { datos, loading, claseSelected, clases } = this.state;
    return (
      <div>
        <Segment loading={loading}>
          <Grid>
            <Grid.Column width={12}>
              <Header as="h1">
                <Icon name="address book" />
                <Header.Content>Datos del Grupo Pequeño</Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={4}>
              <Link to={`edit-datos-grupo/${claseSelected}`}>
              <Icon name='edit' size="big" color="blue"/>
              </Link>
            </Grid.Column>
          </Grid>
          
          <Divider />

          <Grid style={{ padding: "0 20px"}}>
            <Grid.Row columns={4} textAlign="left">
              <Grid.Column>
                <Form.Field>
                  <Select
                    label="Clase"
                    value={claseSelected}
                    options={clases}
                    name="claseSelected"
                    onChange={this.handleChangeClass}
                  />
                </Form.Field>
                
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Table definition>
                  <Table.Body>
                    {
                      Object.entries(keysDatos).map(d => (
                          <Table.Row>
                            <Table.Cell>{d[1]}</Table.Cell>
                            <Table.Cell>{datos[d[0]]}</Table.Cell>
                          </Table.Row>
                        ))
                    }
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment loading={loading}>
        <Grid>
          <Grid.Column width={12}>
            <Header as="h1">
              <Icon name="address book" />
              <Header.Content>Nombramientos en el Grupo Pequeño</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Link to={`edit-datos-grupo/${claseSelected}`}>
            <Icon name='edit' size="big" color="blue"/>
            </Link>
          </Grid.Column>
        </Grid>
        
        <Divider />

        <Grid style={{ padding: "0 20px"}}>
          <Grid.Row>
            <Grid.Column>
              <Table definition>
                <Table.Body>
                  {
                    Object.entries(keysNombramientos).map(d => {
                      return (
                        <Table.Row>
                          <Table.Cell>{d[1]}</Table.Cell>
                          <Table.Cell>{datos[d[0]] ? datos[d[0]].full_name : "N/A" }</Table.Cell>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </Segment>

      </div>
    )
  }
}

export default DatosGrupo;