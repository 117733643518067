import React, { useContext, useEffect } from "react";
import {
  Segment,
  Grid,
  Table,
  Header,
  Divider,
  Icon,
  Select,
  Form,
  Label
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import feathers from "../../feathers-client";
import swal from "sweetalert";
import moment from "moment";
import CatalogosContext from '../../Context/CatalogosContext/catalogosContext'

const ListaMiembrosGP = () =>  {
  const catalogosContext = useContext(CatalogosContext);
  const { loading, clases, miembros,claseSelected, setClaseSelected, setLoading, getClases, getMiembros } = catalogosContext;
 
  useEffect(() => {
    getClases(true);
  }, [])

  useEffect(() => {
    if(clases.length !== 0) {
      getMiembros(claseSelected)
    }
  }, [clases])


  const handleDelete = async (Id) => {
    setLoading(true)
    await swal({
      title: "¿Seguro desea eliminar el miembro?",
      text:
        "Esta operación es irreversible",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async willDelete => {
      if (willDelete) {
        await feathers
          .service("students-grupo")
          .remove(Id)
          .then(res => {
            swal("Miembro eliminado correctamente", { icon: "success", timer: 2000 });
            getMiembros(claseSelected);
          })
          .catch(err => {
            console.log(err);
            swal("Ocurrió un error. Intente más tarde", { icon: "error" });
          });
      }
    });
    setLoading(false)
  };

  const handleChangeClass = (event, result) => {
    setLoading(true)
    const { value } = result || event.target;
    setClaseSelected(value)
    getMiembros(value)
    setLoading(false)
  }

    return(
      <Segment loading={loading}>
        <Grid>
          <Grid.Column width={12}>
            <Header as="h1">
              <Icon name="list" />
              <Header.Content>Listado de Miembros de Grupo Pequeño</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Link to="crear-miembro-gp">
            <Icon name='plus' size="big" color="green" />
            </Link>
          </Grid.Column>
        </Grid>
         
        <Divider />

        <Grid style={{ padding: "0 20px"}}>
          <Grid.Row columns={4} textAlign="left">
            <Grid.Column>
              <Form.Field>
                <Select
                  label="Clase"
                  value={claseSelected}
                  options={clases}
                  name="claseSelected"
                  onChange={handleChangeClass}
                />
              </Form.Field>
              
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Fecha de Nacimiento</Table.HeaderCell>
                  <Table.HeaderCell>Tags</Table.HeaderCell>
                  <Table.HeaderCell>Acciones</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {miembros.map((a, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <Label color={a.type === "MIEMBRO" ? 'green' : 'blue'} ribbon>
                        {a.type}
                      </Label>
                    </Table.Cell>
                    
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{a.full_name}</Table.Cell>
                    <Table.Cell>{moment(a.birthdate).format("DD/MM/YYYY")}</Table.Cell>
                    <Table.Cell>
                      <Label color='orange' tag>
                      {a.age}
                      </Label>
                    </Table.Cell>
                    <Table.Cell>
                      <Link to="#" onClick={() => handleDelete(a.id)}>
                        <Icon name="trash" color="grey" /> Eliminar
                      </Link>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Link to={`/editar-miembro-gp/${a.id}`}>
                        <Icon name="pencil" color="grey" /> Editar
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </Segment>
    )
}

export default ListaMiembrosGP;