import React, { useContext, useEffect, useState } from 'react'
import { GroupContext } from '../../../Context/GroupContext/GroupContext';
import { Table, Checkbox, Input, Divider, Segment, Dropdown, Form, Grid, Header, Icon } from 'semantic-ui-react'
import feathers from "../../../feathers-client";

const EDITABLE_AIM = false;

const MESES = [
    {key: 1, text: "Enero", value :1},
    {key: 2, text: "Febrero", value :2},
    {key: 3, text: "Marzo", value :3},
    {key: 4, text: "Abril", value :4},
    {key: 5, text: "Mayo", value :5},
    {key: 6, text: "Junio", value :6},
    {key: 7, text: "Julio", value :7},
    {key: 8, text: "Agosto", value :8},
    {key: 9, text: "Septiembre", value :9},
    {key: 10, text: "Octubre", value :10},
    {key: 11, text: "Noviembre", value :1},
    {key: 12, text: "Diciembre", value :1},    
];

const now = new Date().getUTCFullYear();    
const YEARS = Array(now - (now - 20)).fill('').map((v, idx) => now - idx).map(v => {
    return {
        key: v,
        text: v,
        value: v
    }
});



const useHookToGetInitialData = () => {
    const ContextProps = useContext(GroupContext)
    
    useEffect(() => {
        
        const task = feathers.service("task");

        task.find().then(res => {
            ContextProps.setObjetivos(res.data);
        });


    }, [])

    return {InitialState : useContext(GroupContext) }
}

export default function Objetivos({ claseId }) {
    
    const { InitialState } = useHookToGetInitialData();

    const [Month, setMonth] = useState(1)
    const [Year, setYear] = useState(2021)
    const [currentClaseId, setCurrentClaseId] = useState(0)

    const token = JSON.parse(localStorage.getItem("token"));

    const [Loading, setLoading] = useState(false)
    
    useEffect(() => {
        setLoading(true)
        
    }, [Month, Year])

    useEffect(() => {
        const currentClaseIdTemp = claseId ? claseId : (token ? token.clase_id : null)
        setCurrentClaseId(currentClaseIdTemp)
    }, [claseId])

    return (
        <Segment loading={Loading}>
            <Grid>
                <Grid.Column width={12}>
                    <Header as="h1">
                    <Icon name="list" />
                    <Header.Content>Objetivos del Grupo Pequeño</Header.Content>
                    </Header>
                </Grid.Column>
            </Grid>
            <Segment>
                <Form>
                    <Form.Group widths='equal' inline>
                        <div style={{flex: 1}}>
                            <Dropdown search selection placeholder="Mes" options={MESES} value={Month} onChange={ (_, d) => setMonth(d.value)} />
                        </div>
                        <div style={{flex: 1}}>
                            <Dropdown search selection placeholder="Año" options={YEARS} value={Year} onChange={ (_, d) => setYear(d.value)}/>
                        </div>
                    </Form.Group>
                </Form>
            </Segment>
            <Divider  horizontal/>
             <Table celled  selectable >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Edición</Table.HeaderCell>
                        <Table.HeaderCell>Objetivos</Table.HeaderCell>
                        <Table.HeaderCell>Blancos</Table.HeaderCell>
                        <Table.HeaderCell>Alcanzado</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {InitialState.Objetivos && InitialState.Objetivos.map( (v, i) => {
                        return <TaskRow key={i} index={i} dataset={v} month={Month} year={Year} group={currentClaseId} setLoading={setLoading}/>
                    })}
                </Table.Body>
             </Table>
        </Segment>
    )
}

const useTaskData = (pro) => {
    const taskgroup = feathers.service("taskgroup");

    const [TaskData, setTaskData] = useState(null)

    useEffect(() => {
        

        taskgroup.find({
            query: {
                month: pro.month,
                year: pro.year,
                taskid: pro.dataset.id,
                claseid: pro.group
            }
        }).then(res => {
            setTaskData(res.data[0] ? res.data[0] : {
                done: 0,
                month: pro.month,
                year: pro.year,
                taskid: pro.dataset.id,
                claseid: pro.group,
            })
        });
        
    }, [pro])

    return  { TaskData, setTaskData, taskgroup } 
}

const useCheckbox = () => {

    const [Checked, setChecked] = useState(false)
    return {Checked, setChecked}
}

 function TaskRow(props) {

    const { TaskData, setTaskData, taskgroup } = useTaskData(props)
    const {Checked, setChecked} = useCheckbox();
   

    useEffect(() => {
        if(!Checked && TaskData && TaskData.done){
            taskgroup.find({
                query: {
                    month: props.month,
                    year: props.year,
                    taskid: props.dataset.id,
                    claseid: props.group
                }
            }).then( res => {
                if(res.data.length == 0){
                    taskgroup.create(TaskData)
                }else if(res.data && res.data.length > 0){
                    taskgroup.patch(res.data[0].id, TaskData)
                }
            })
        }
    }, [Checked, TaskData])

    const ChangeAim = (aim) => {
        setTaskData({
            ...TaskData,
            aim,
        })
    }

    const ChangeDone = (done) => {
        setTaskData({
            ...TaskData,
            done,
        })
    }
    
    return (
        <Table.Row key={props.index}  >
            <Table.Cell collapsing>
                <Checkbox slider checked={Checked} onChange={ (e, d) => {setChecked(d.checked)}} />
            </Table.Cell>
            <Table.Cell>{props.dataset.task}</Table.Cell>
            <AimCell TaskData={TaskData} Checked={Checked} ChangeAim={ChangeAim} setChecked={setChecked} setLoading={props.setLoading}/>
            <DoneCell TaskData={TaskData} Checked={Checked} ChangeDone={ChangeDone} setChecked={setChecked} setLoading={props.setLoading}/>        

        </Table.Row>
    )
}

function AimCell({TaskData, Checked, ChangeAim, setChecked, setLoading}){

    const taskobjetives = feathers.service("taskobjetives");
    const [Aim, setAim] = useState(0)


    
    useEffect(() => {
        

        const query = {
            "task" : TaskData && TaskData.taskid ? TaskData.taskid : 0,
            "year" : TaskData && TaskData.year ? TaskData.year :0,
        }
       taskobjetives.find({
           query
       }).then(v => {
            setLoading(false)

            if(v.data && v.data[0]) setAim(v.data[0].aim)
            else setAim(0)
       }).catch(err => {
           console.log("Error")
       });
    }, [TaskData])


    if(EDITABLE_AIM){
        if (Checked) return (
            <Table.Cell>
                <Input 
                    placeholder={Aim} size="mini" 
                    onChange={(e, {value}) => ChangeAim(value)}
                    onKeyDown={ e => {
                        if(e.keyCode === 13) setChecked(false)
                    }}
                />
            </Table.Cell>
        )
    }
    return <Table.Cell onDoubleClick={_ => setChecked(true)}>{Aim}</Table.Cell>
}

function DoneCell({TaskData, Checked, ChangeDone, setChecked}){

    if (Checked) return (
        <Table.Cell>
            <Input 
                placeholder={TaskData  && TaskData.done ? TaskData.done : 0} size="mini" 
                onChange={(e, {value}) => ChangeDone(value)}
                onKeyDown={ e => {
                    if(e.keyCode === 13) setChecked(false)
                }}
            />
        </Table.Cell>
    )
    return <Table.Cell onDoubleClick={_ => setChecked(true)} >{TaskData && TaskData.done ? TaskData.done : 0}</Table.Cell>    
}