import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Segment, Grid, Header, Form, Select, Table, Icon, Divider, Label } from 'semantic-ui-react';
import moment from "moment";
import ReporteSemaforo from './ReporteSemaforo';

import PaseListaGrupo from '../../PaseListaGrupo';
import CatalogosContext from '../../../Context/CatalogosContext/catalogosContext'
import Objetivos from '../Objetivos/Objetivos'

const ReporteClaseGrupo = () => {
  const catalogosContext = useContext(CatalogosContext);
  const {clases, miembros, claseSelected, setClaseSelected, getClases, getMiembros } = catalogosContext;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getClases(true);
  }, []);

  useEffect(() => {
    if(clases.length !== 0) {
      getMiembros(claseSelected)
    }
  }, [clases])

  const handleChangeClass = async (event, result) => {
    setLoading(true)
    const { value } = result || event.target;
    setClaseSelected(value)
    getMiembros(value)
    setLoading(false)
  }

  return (
    <Fragment>
      <Segment loading={loading}>
        <Grid>
          <Grid.Column width={12}>
            <Header as="h1" textAlign="left">
              <Header.Content>Seleccionar Clase</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Row columns={4} textAlign="left">
            <Grid.Column>
              <Form.Field>
                <Select
                  label="Clase"
                  value={claseSelected}
                  options={clases}
                  name="claseSelected"
                  onChange={handleChangeClass}
                />
              </Form.Field>
              
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      
      <Segment loading={loading}>
        <Grid>
          <Grid.Column width={12}>
            <Header as="h1">
              <Icon name="list" />
              <Header.Content>Listado de Miembros de Grupo Pequeño</Header.Content>
            </Header>
          </Grid.Column>
        </Grid>
         
        <Divider />

        <Grid style={{ padding: "0 20px"}}>
          <Grid.Row width={16}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Fecha de Nacimiento</Table.HeaderCell>
                  <Table.HeaderCell>Tags</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {miembros.map((a, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <Label color={a.type === "MIEMBRO" ? 'green' : 'blue'} ribbon>
                        {a.type}
                      </Label>
                    </Table.Cell>
                    
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{a.full_name}</Table.Cell>
                    <Table.Cell>{moment(a.birthdate).format("DD/MM/YYYY")}</Table.Cell>
                    <Table.Cell>
                      <Label color='orange' tag>
                      {a.age}
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment loading={loading}>
        <h1>Reporte por clase de Grupo Pequeño</h1>
        <PaseListaGrupo claseId={claseSelected} showSemaforo={false} />
      </Segment>

      <ReporteSemaforo claseId={claseSelected} />

      {/* OBJETIVOS */}
      <Objetivos claseId={claseSelected} />
    </Fragment>
  );

};

export default ReporteClaseGrupo;