import React, { Component, Fragment } from "react";
import { Segment, Grid, Header, Icon, Divider, Form, Select, Table, Button } from "semantic-ui-react";
import feathers from "../../feathers-client";
import sabaths from "../../utils/sabaths";
import quarters from "../../utils/quarters";

import { formatPercentage } from '../../utils/functions';

class Reporte extends Component {

  state = {
    loading: true,
    loadingGeneral: true,
    sabaths: [],
    quarters: quarters,
    dateSelected: "2021-01-02",
    quarterSelected: "2021Q1",
    clases: [],
    promedios: [],
    general: [0, 0, 0],
    asistenceToTable: [],
    studyToTable: [],
    verseToTable: []
  }

  componentDidMount() {
    const sabathsFiltered = sabaths.filter(s => s.quarter === this.state.quarterSelected)
    this.setState({ sabaths: sabathsFiltered })
    this.getReport(this.state.dateSelected, this.state.quarterSelected);
    this.getGeneralReport(this.state.quarterSelected)
  }

  getReport = (date, quarter) => {
    const report = feathers.service("reports");
    report.find({
      query: {
        date: date,
        quarter: quarter
      }
    })
    .then(res => {
      this.setState({ 
        loading: false,
        clases: res.clases,
        promedios: res.promedios,
        general: res.general
      })
    })
    .catch(err => {
      console.log(err);
    })
  }

  getGeneralReport = (quarter) => {
    this.setState({ asistenceToTable: [], studyToTable: [], verseToTable: [], loadingGeneral: true })

    feathers.service("reports").get(quarter)
      .then(res => {
        console.log(res);

        this.setState({
          asistenceToTable: res.arrAsistence, 
          studyToTable: res.arrStudy, 
          verseToTable: res.arrVerse, 
          loadingGeneral: false 
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

  handleChangeSabath = (event, result) => {
    const { name, value } = result || event.target;
    this.setState({ [name]: value, loading: true, clases: [], promedios: [], general: [0, 0, 0] })
    this.getReport(value, this.state.quarterSelected)
  }

  handleChangeQuarter = (event, result) => {
    const { name, value } = result || event.target;
    const sabathsFiltered = sabaths.filter(s => s.quarter === value)
    this.setState({ 
      [name]: value, 
      loading: true, 
      clases: [], 
      promedios: [], 
      general: [0, 0, 0], 
      sabaths: sabathsFiltered, 
      dateSelected: sabathsFiltered[0].value })
    this.getReport(sabathsFiltered[0].value, value)
    this.getGeneralReport(value)
  }

  handleExcel = async () => {
    this.setState({ loading: true })
    const excel = feathers.service("download-report");
    await excel.find({
      query: {
        date: this.state.dateSelected
      }
    })
    .then(res => {
      this.setState({ loading: false })
      window.open(
        `${process.env.REACT_APP_IP_API}/${res.data.split("/")[1]}`,
        "_blank"
      )
    })
    .catch(err => {
      console.log(err);
    })
  }

  render() {
    const { loading, loadingGeneral, sabaths, quarters, dateSelected, quarterSelected, clases, promedios, general } = this.state

    return (
      <Fragment>
        <Segment loading={loading}>
          <Grid>
            <Grid.Column width={12}>
              <Header as="h1">
                <Icon name="file excel" />
                  <Header.Content>Reporte de Clases del {dateSelected}</Header.Content>
              </Header>
            </Grid.Column>
          </Grid>
          
          <Divider />

          <Grid style={{ padding: "0 20px"}}>
            <Grid.Row columns={4} textAlign="left">

              <Grid.Column>
                <Form.Field>
                  <Select
                    label="Trimestre"
                    value={quarterSelected}
                    options={quarters}
                    name="quarterSelected"
                    onChange={this.handleChangeQuarter}
                  />
                </Form.Field>
              </Grid.Column>
              
              <Grid.Column>
                <Form.Field>
                  <Select
                    label="Clase"
                    value={dateSelected}
                    options={sabaths}
                    name="dateSelected"
                    onChange={this.handleChangeSabath}
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Button
                  icon="file excel"
                  positive
                  content="Exportar"
                  onClick={this.handleExcel}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row width={16}>

              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell>Clase</Table.HeaderCell>
                    <Table.HeaderCell>
                      <Icon name="clock"/>
                      Asistencia
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Icon name="book"/>
                      Estudio Diario
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Icon name="check"/>
                      Versículo
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    clases.map((c, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{ i+1 }</Table.Cell>
                        <Table.Cell>{ c }</Table.Cell>
                        <Table.Cell positive={promedios[i][0]>=0.80} warning={promedios[i][0]>=0.60&&promedios[i][0]<0.80} negative={promedios[i][0]<0.60}>
                          { `${ formatPercentage(promedios[i][0]) }%` }
                        </Table.Cell>
                        <Table.Cell positive={promedios[i][1]>=0.80} warning={promedios[i][1]>=0.60&&promedios[i][1]<0.80} negative={promedios[i][1]<0.60}>
                          { `${ formatPercentage(promedios[i][1]) } %` }
                        </Table.Cell>
                        <Table.Cell positive={promedios[i][2]>=0.80} warning={promedios[i][2]>=0.60&&promedios[i][2]<0.80} negative={promedios[i][2]<0.60}>
                          { `${ formatPercentage(promedios[i][0]) }%` }
                        </Table.Cell>
                      </Table.Row>
                    ))
                  }
                  <Table.Row>
                    <Table.Cell>{ clases.length + 1 }</Table.Cell>
                    <Table.Cell>General</Table.Cell>
                    <Table.Cell positive={general[0]>=0.80} warning={general[0]>=0.60&&general[0]<0.80} negative={general[0]<0.60}>
                      { `${formatPercentage(general[0])}%` }
                    </Table.Cell>
                    <Table.Cell positive={general[1]>=0.80} warning={general[1]>=0.60&&general[1]<0.80} negative={general[1]<0.60}>
                      { `${formatPercentage(general[1])}%` }
                      </Table.Cell>
                    <Table.Cell positive={general[2]>=0.80} warning={general[2]>=0.60&&general[2]<0.80} negative={general[2]<0.60}>
                      { `${formatPercentage(general[2])}%` }
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>

              </Table>

            </Grid.Row>
          </Grid>
        </Segment>

        <Segment loading={loadingGeneral}>
          <Grid>
            <Grid.Column width={12}>
              <Header as="h1">
                <Icon name="file" />
                  <Header.Content>Reporte General</Header.Content>
              </Header>
            </Grid.Column>
          </Grid>
          
          <Divider />

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='2'>
                  Clase / Lección
                </Table.HeaderCell>
                {
                  sabaths.map(m => 
                    <Table.HeaderCell>
                      {m.text.substring(8,10)}
                    </Table.HeaderCell>
                  )
                }
                <Table.HeaderCell>
                  Promedio General
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                this.state.asistenceToTable.map((a, j) => <Fragment>
                  <Table.Row>
                    {
                      a.map((cell, i) => 
                        (
                          i === 0 
                            ?
                              <Fragment>
                                <Table.Cell rowSpan='3'>{cell.substring(6,20)}</Table.Cell>
                                <Table.Cell><Icon name="clock"/><strong>A</strong></Table.Cell>
                              </Fragment>
                            :
                            (typeof cell === "string"
                              ?
                                <Table.Cell></Table.Cell>
                              :
                                <Table.Cell positive={cell>=0.80} warning={cell>=0.60&&cell<0.80} negative={cell<0.60}>
                                  {formatPercentage(cell) + "%"}
                                </Table.Cell>)
                        )
                        )
                      }
                  </Table.Row>
                  <Table.Row>
                      {
                        this.state.studyToTable[j].map((cell, i) => (
                          i === 0 
                          ?
                            <Table.Cell><Icon name="book"/><strong>E</strong></Table.Cell>
                          :
                          (typeof cell === "string"
                            ?
                              <Table.Cell></Table.Cell>
                            :
                              <Table.Cell positive={cell>=0.80} warning={cell>=0.60&&cell<0.80} negative={cell<0.60}>
                                {formatPercentage(cell) + "%"}
                              </Table.Cell>)
                        ))
                      }
                  </Table.Row>
                  <Table.Row>
                      {
                        this.state.verseToTable[j].map((cell, i) => (
                          i === 0 
                          ?
                            <Table.Cell><Icon name="check"/><strong>V</strong></Table.Cell>
                          :
                          (typeof cell === "string"
                            ?
                              <Table.Cell></Table.Cell>
                            :
                              <Table.Cell positive={cell>=0.80} warning={cell>=0.60&&cell<0.80} negative={cell<0.60}>
                                {formatPercentage(cell) + "%"}
                              </Table.Cell>)
                        ))
                      }
                  </Table.Row>
                </Fragment>
                )
              }
            </Table.Body>
          </Table>

        </Segment>

      </Fragment>
    )
  }
}

export default Reporte;