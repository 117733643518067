const quarters = [
  {
    key: "2020Q4",
    text: "Trim 4 2020",
    value: "2020Q4"
  },
  {
    key: "2021Q1",
    text: "Trim 1 2021",
    value: "2021Q1"
  }
];

export default quarters;