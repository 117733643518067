import React, { useContext, useEffect } from 'react';
import ReporteContext from "../../Context/ReporteContext/reporteContext";
import AuthContext from "../../Context/Auth/authContext"
import { Table, Icon } from "semantic-ui-react";

const ReporteSemaforo = () => {
  const authContext = useContext(AuthContext)
  const reporteContext = useContext(ReporteContext)
  const { reporte, getReporteSemaforoClase } = reporteContext;
  const { user } = authContext

  useEffect(() => {
    // console.log(user);
    const token = JSON.parse(localStorage.getItem("token"));
    const claseId = token ? token.clase_id : 1
    getReporteSemaforoClase(claseId)
  }, [])

  return (
    <Table unstackable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            Lección
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            <Icon name="clock"/>
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            <Icon name="book"/>
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            <Icon name="check"/>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          reporte.map((row, i) => 
            <Table.Row>
              <Table.Cell>{ row[0] && row[0] <= 13 ? row[0] : "Promedio General" }</Table.Cell>

              {
                row[1] === "N/A" 
                ?
                  <Table.Cell></Table.Cell>
                :
                  <Table.Cell positive={row[1]>=0.80} warning={row[1]>=0.60&&row[1]<0.80} negative={row[1]<0.60}>
                    {Math.round(row[1] * 100) + "%"}
                  </Table.Cell>
              }

              {
                row[2] === "N/A" 
                ?
                  <Table.Cell></Table.Cell>
                :
                  <Table.Cell positive={row[2]>=0.80} warning={row[2]>=0.60&&row[2]<0.80} negative={row[2]<0.60}>
                    {Math.round(row[2] * 100) + "%"}
                  </Table.Cell>
              }

              {
                row[3] === "N/A" 
                ?
                  <Table.Cell></Table.Cell>
                :
                  <Table.Cell positive={row[3]>=0.80} warning={row[3]>=0.60&&row[3]<0.80} negative={row[3]<0.60}>
                    {Math.round(row[3] * 100) + "%"}
                  </Table.Cell>
              }


            </Table.Row>
          )
        }
      </Table.Body>
    </Table>
  )
}

export default ReporteSemaforo