import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { GeneralProvider } from "./Context/GeneralContext"

import App from "./Components/App";
import ListaClases from "./Components/Clases"
import CreateClass from "./Components/Clases/Create"
import EditClass from "./Components/Clases/Edit"
import ListaMaestros from "./Components/Maestros"
import CreateMaestro from "./Components/Maestros/Create"
import EditMaestro from "./Components/Maestros/Edit"
import ListaAlumnos from "./Components/Alumnos"
import CreateAlumno from "./Components/Alumnos/Create"
import EditAlumno from "./Components/Alumnos/Edit"
import Login from "./Components/Login"
import PaseLista from "./Components/PaseLista"
import Reporte from "./Components/Reporte"
import DatosGrupo from "./Components/DatosGrupo"
import EditDatosGrupo from "./Components/DatosGrupo/Edit"
import ReporteClase from "./Components/ReporteClase";
import Objetivos from "./Components/DatosGrupo/Objetivos/Objetivos";
import PaseListaGrupo from './Components/PaseListaGrupo'
import ListaMiembrosGP from './Components/MiembrosGP/Lista'
import CreateMiembroGP from './Components/MiembrosGP/Create'
import EditMiembroGP from './Components/MiembrosGP/Edit'
import ReporteObjetivos from "./Components/DatosGrupo/ReporteObjetivos/ReporteObjetivos"
import ReporteClaseGrupo from './Components/DatosGrupo/ReporteClase/ReporteClase'
import ReporteGeneralGrupo from "./Components/DatosGrupo/ReporteGeneralGrupo/ReporteGeneralGrupo";
import ListaIntereses from './Components/Intereses';
import AltaInteres from './Components/Intereses/AltaInteres';
import EditInteres from './Components/Intereses/EditInteres';

const AppRoutes = () => (
  <GeneralProvider>
    <App>
      <Switch>
        {/* Routes Pase Lista */}
        <PrivateRoute exact path="/pase-lista" component={PaseLista} />
        <PrivateRoute exact path="/pase-lista-grupo" component={PaseListaGrupo} />

        {/* Routes Datos Grupo */}
        <PrivateRoute exact path="/datos-grupo" component={DatosGrupo} />
        <PrivateRoute exact path="/edit-datos-grupo/:id" component={EditDatosGrupo} />

        {/* Routes Loging */}
        <PrivateRouteReverse path="/login" component={Login} />
        <PrivateRoute exact path="/" component={DatosGrupo} />

        {/* Routes Clases */}
        <PrivateRouteAdmin exact path="/clases" component={ListaClases} />
        <PrivateRouteAdmin path="/crear-clase" component={CreateClass} />
        <PrivateRouteAdmin path="/editar-clase/:id" component={EditClass} />

        {/* Routes Maestros */}
        <PrivateRouteAdmin path="/maestros" component={ListaMaestros} />
        <PrivateRouteAdmin path="/crear-maestro" component={CreateMaestro} />
        <PrivateRouteAdmin path="/editar-maestro/:id" component={EditMaestro} />

        {/* Routes Alumnos */} 
        <PrivateRouteAdmin path="/alumnos" component={ListaAlumnos} />
        <PrivateRouteAdmin path="/crear-alumno" component={CreateAlumno} />
        <PrivateRouteAdmin path="/editar-alumno/:id" component={EditAlumno} />

        {/* Routes Reporte */} 
        <PrivateRouteAdmin path="/reporte" component={Reporte} />
        <PrivateRouteAdmin path="/reporte-clase" component={ReporteClase} />
        <PrivateRouteAdmin path="/reporte-clase-gp" component={ReporteClaseGrupo} />
        <PrivateRoute exact path="/group/objetivos/reporte" component={ReporteObjetivos} />
        <PrivateRoute exact path="/group/reporte-general" component={ReporteGeneralGrupo} />

        {/* Routes Miembros GP */} 
        <PrivateRouteAdmin exact path="/miembros-gp" component={ListaMiembrosGP} />
        <PrivateRouteAdmin exact path="/crear-miembro-gp" component={CreateMiembroGP} />
        <PrivateRouteAdmin path="/editar-miembro-gp/:id" component={EditMiembroGP} />

        <PrivateRoute exact path="/group/objetivos" component={Objetivos} />

        {/* Routes Intereses */}
        <PrivateRoute exact path="/intereses" component={ListaIntereses} />
        <PrivateRoute exact path="/alta-interes" component={AltaInteres} />
        <PrivateRoute path="/editar-interes/:id" component={EditInteres} />

      </Switch>
    </App>
  </GeneralProvider>
);

const token = JSON.parse(localStorage.getItem("token"));

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      token !== null ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
    }
  />
);

const PrivateRouteAdmin = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      token !== null && token.rol === "Director" ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: "/reporte",
              state: { from: props.location }
            }}
          />
        )
    }
  />
);

const PrivateRouteReverse = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      token === null ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: "/pase-lista",
              state: { from: props.location }
            }}
          />
        )
    }
  />
);

export default AppRoutes;