import React, { useContext, useEffect } from 'react';
import ReporteContext from "../../Context/ReporteContext/reporteContext";
import { Table, Icon } from "semantic-ui-react";

const ReporteSemaforo = () => {
  const reporteContext = useContext(ReporteContext)
  const { reporteGP, getReporteSemaforoClaseGP } = reporteContext;

  useEffect(() => {
    // console.log(user);
    const token = JSON.parse(localStorage.getItem("token"));
    const claseId = token ? token.clase_id : 1
    getReporteSemaforoClaseGP(claseId)
  }, [])

  return (
    <Table unstackable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            Semana
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            <Icon name="clock"/>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          reporteGP.map((row, i) => 
            <Table.Row key={i}>
              <Table.Cell>{ row[0] && row[0] <= 13 ? row[0] : "Promedio General" }</Table.Cell>

              {
                row[1] === "N/A" 
                ?
                  <Table.Cell></Table.Cell>
                :
                  <Table.Cell positive={row[1]>=0.80} warning={row[1]>=0.60&&row[1]<0.80} negative={row[1]<0.60}>
                    {Math.round(row[1] * 100) + "%"}
                  </Table.Cell>
              }

            </Table.Row>
          )
        }
      </Table.Body>
    </Table>
  )
}

export default ReporteSemaforo