export const INITIAL_DATA = {
  full_name: "",
  clase_id: 0,
  birthdate: "2021-01-01",
  tags: "",
  trimestre: "2021Q1",
  type: "MIEMBRO",
  age: "ADULTO"
};

export const TYPE_DATA = [
  {
    key: 0,
    text: "Miembro",
    value: "MIEMBRO"
  },
  {
    key: 1,
    text: "Visita",
    value: "VISITA"
  }
];

export const AGE_DATA = [
  {
    key: 0,
    text: "Niño",
    value: "NIÑO"
  },
  {
    key: 1,
    text: "Joven",
    value: "JOVEN"
  },
  {
    key: 2,
    text: "Adulto",
    value: "ADULTO"
  }
];
