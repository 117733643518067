import { GET_REPORTE_SEMAFORO, GET_REPORTE_SEMAFORO_GP } from "../types"

export default (state, action) => {
  switch (action.type) {
    case GET_REPORTE_SEMAFORO:
      return {
        ...state,
        reporte: action.payload
      }
    case GET_REPORTE_SEMAFORO_GP:
      return {
        ...state,
        reporteGP: action.payload
      }
    default:
      return state
  }
}